angular.module('enervexSalesappApp').controller('WinBankBuilderCtrl', function($scope, $uibModalInstance, items) {

	LayoutData = items;

	$scope.okBank = function() {
		var modalInstance = $uibModalInstance.close();
	}

	$scope.canBank = function() {
		var modalInstance = $uibModalInstance.close();
	}

});
